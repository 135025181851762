import React, {FunctionComponent} from 'react';
import Button from './Button';
import {BookIcon, ClipboardIcon, DownloadIcon, InfoIcon, PlayIcon, ToujeoIcon} from './icons';

export interface HomeBlockProps {
    title: string;
    icon: 'download' | 'info' | 'book' | 'clipboard' | 'toujeo' | 'play';
    text: string;
    buttons: Array<{
        to: string;
        text: string;
    }>;
}

interface Props {
    data: HomeBlockProps;
}

const HomeBlock: FunctionComponent<Props> = ({data}) => {
    require(`../styles/${process.env.THEME}/home-blocks.scss`);

    const icons = {
        download: <DownloadIcon/>,
        info: <InfoIcon/>,
        book: <BookIcon/>,
        clipboard: <ClipboardIcon/>,
        toujeo: <ToujeoIcon/>,
        play: <PlayIcon/>
    };

    return (
        <section className='home-block flex flex-col p-6 rounded-md stacky-4 lg:w-1/4'>
            <h1 className='flex items-center pb-4 text-2xl font-bold border-b border-dashed'>
                <span className='w-8 h-8 mr-4 icon'>
                    {icons[data.icon]}
                </span>
                {data.title}
            </h1>
            <p className='flex-grow'>{data.text}</p>
            {data.buttons.length > 0 &&
            <div className={'self-end flex flex-col'}>
                {
                    data.buttons.map((button, index) => {
                        return (
                            <Button asLink={button.to} className='self-end button mt-4 w-full' key={data.title + index}>
                                {button.text}
                            </Button>
                        );
                    })
                }
            </div>
            }
        </section>
    );
};

export default HomeBlock;
