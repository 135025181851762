import React, { useEffect } from 'react';

const OneTrustManager = ({id}) => {
    useEffect(() => {
        const loadScript = () => {
            // Check if the script has already been loaded
            if (!window.OptanonWrapper) {
                const script = document.createElement('script');
                script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
                script.type = 'text/javascript';
                script.charset = 'UTF-8';
                script.dataset.domainScript = id; // Replace '{{ID}}' with your actual ID
                script.async = true;
                script.onload = () => {
                    // Once script is loaded, initialize OneTrust
                    window.OptanonWrapper = function() {};
                };

                // Append the script to the head of the document
                document.head.appendChild(script);
            }
        };

        loadScript();

        // Cleanup function to remove the script if the component unmounts
        return () => {
            const existingScript = document.querySelector(
                'script[src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"]'
            );
            if (existingScript) {
                existingScript.remove();
                delete window.OptanonWrapper;
            }
        };
    }, []);

    return (
        <></>
    );
};

export default OneTrustManager;