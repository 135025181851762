import React, {FunctionComponent} from 'react';
import IconProps from '../../types/IconProps';

const DownloadIcon: FunctionComponent<IconProps> = ({size = 'w-8 h-8'}) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55 55' className={`${size} fill-current`}>
            <defs>
                <clipPath id='a'>
                    <path data-name='Path 430' d='M0 6.038h55v-55H0z' transform='translate(0 48.962)' fill='#6f1d46'/>
                </clipPath>
            </defs>
            <g data-name='Group 46'>
                <g data-name='Group 45' clipPath='url(#a)'>
                    <g data-name='Group 41'>
                        <path
                            data-name='Path 426'
                            d='M53.281 55H1.72A1.709 1.709 0 010 53.3V37.37a1.709 1.709 0 011.72-1.7 1.708 1.708 0 011.718 1.7v14.236h48.124V37.37a1.709 1.709 0 011.719-1.7A1.709 1.709 0 0155 37.37v15.932a1.709 1.709 0 01-1.719 1.7'
                            fill='#6f1d46'/>
                    </g>
                    <g data-name='Group 42'>
                        <path
                            data-name='Path 427'
                            d='M27.804 47.021a1.725 1.725 0 01-1.216-.5L8.572 28.735a1.681 1.681 0 010-2.4 1.733 1.733 0 012.43 0l15.08 14.891-.011-39.529a1.708 1.708 0 011.718-1.7 1.71 1.71 0 011.72 1.7l.012 43.627a1.7 1.7 0 01-1.061 1.568 1.717 1.717 0 01-.656.129'
                            fill='#6f1d46'/>
                    </g>
                    <g data-name='Group 43'>
                        <path
                            data-name='Path 428'
                            d='M34.691 39.064a1.724 1.724 0 01-1.215-.5 1.681 1.681 0 010-2.4l7.879-7.565a1.731 1.731 0 012.43 0 1.681 1.681 0 010 2.4l-7.879 7.571a1.724 1.724 0 01-1.215.5'
                            fill='#6f1d46'/>
                    </g>
                    <g data-name='Group 44'>
                        <path
                            data-name='Path 429'
                            d='M47.354 26.262a1.4 1.4 0 01-1.413 1.4 1.4 1.4 0 01-1.413-1.4 1.4 1.4 0 011.413-1.4 1.4 1.4 0 011.413 1.4'
                            fill='#6f1d46'/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default DownloadIcon;
